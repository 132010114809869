/**
 * Compress, Optimize and minify SVG
 *
 * https://svgomg.net/
 * https://svgoptimizer.com/fr/
 */

svg {
    &.icon {
        display: inline-block;
        //width: 1em;
        //height: 1em;
        font-size: 1.5em;
        vertical-align: middle;
    }

    .nav-link & {
        font-size: 1.25em;
    }
}

.webcam-animate {
    fill: $primary;
    animation: fadeIn 1.5s infinite;

    @keyframes fadeIn {
        0% {
            fill: $primary;
        }
        50% {
            fill: transparent;
        }
        to {
            fill: $primary;
        }
    }
}
