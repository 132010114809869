#profile {
    .profile-head {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-top: -10px;
        margin-left: 8px;
        margin-right: 8px;

        img {
            margin-right: 16px;
            border-radius: 50%;
        }
    }

    .profile-name {
        display: flex;
        align-items: baseline;

        svg {
            display: flex;
            align-self: center;
            margin-right: 8px;
            color: $primary;
        }

        h1 {
            font-size: 16px;
            margin-bottom: 0;
            margin-right: 8px;
        }
    }

    @include media-breakpoint-up(md) {
        .profile-head {
            margin-top: -20px;

            img {
                width: 70px;
                height: 70px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .profile-head {
            margin-top: -30px;
            margin-left: 16px;
            margin-right: 16px;

            img {
                width: 100px;
                height: 100px;
            }

            .profile-name {
                svg {
                    width: 20px;
                    height: 20px;
                }

                h1 {
                    font-size: 24px;
                }
            }
        }
    }
}
