@import "bootstrap";
@import "flags";
@import "icons";
@import "header";
@import "two-cols";
@import "videos";
@import "player";
@import "profile";
@import "ads";

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none !important;
}

:focus-visible {
    outline: 0;
}

.text-shadow {
    text-shadow: 0 0 10px #000, 4px 1px 12px #000;
}

.object-fit-cover {
    object-fit: cover;
}

.modal {
    &.search-autocomplete {
        .modal-content {
            background-color: rgba($dark, .8);
            backdrop-filter: saturate(180%) blur(10px);

            @include media-breakpoint-up(md) {
                form {
                    width: 50% !important;
                }
            }
        }
    }
}
