#twoCols {
    display: grid;
    gap: var(--bs-gap, 1rem);

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;

        #leftCol {
            grid-column: auto/span 12;
            order: 1;
        }
        #rightCol {
            grid-column: auto/span 12;
            min-height: 110px;
        }
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 300px;

        & > :only-child {
            grid-column: 1/-1;
        }
    }
}
