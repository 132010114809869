@include media-breakpoint-only(xs) {
    .ads-skip-one {
        grid-row-start: 2;
    }

    .ads-skip-two {
        grid-row-start: 3;
    }

    .ads-skip-three {
        grid-row-start: 4;
    }
}

.card-ad-video {
    .online {
        position: absolute;
        top: 7px;
        left: 7px;

        width: auto;
        height: auto;
        display: flex;
        align-items: center;

        border-radius: 2px;
        padding: 2px 8px;
        background-color: rgb(0, 158, 0);
        color: #fff;
        font-size: 10px;
        text-transform: uppercase;

        &::before {
            content: '';
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #fff;
            border-radius: 50%;
            margin-right: 5px;

            animation: fadeInOnline 2s infinite;
        }
    }

    .lead {
        top: auto;
        bottom: 0;
        height: auto;
        padding: 5px 5px 15px;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        font-size: 25px;
        line-height: 30px;
        font-weight: bold;

        letter-spacing: .1em;

        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
        text-shadow: 2px 2px 5px #000000;
    }

    .animate-text {
        animation: flashTextAction 3s infinite;
    }
}

.ads {
    @include media-breakpoint-only(xs) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #home & {
        @include media-breakpoint-up(sm) {
            grid-row: 2;

            &.ads-last {
                @include media-breakpoint-up(sm) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(md) {
                    grid-column-start: 9;
                }

                @include media-breakpoint-up(lg) {
                    grid-column-start: 10;
                }

                @include media-breakpoint-up(xl) {
                    grid-column-start: 11;
                }
            }
        }
    }

    #categories &,
    #videos-niches & {
        @include media-breakpoint-up(sm) {
            grid-row: 1;

            &.ads-last {
                @include media-breakpoint-up(sm) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(md) {
                    grid-column-start: 9;
                }

                @include media-breakpoint-up(lg) {
                    grid-column-start: 10;
                }

                @include media-breakpoint-up(xl) {
                    grid-column-start: 11;
                }
            }
        }
    }

    #pornstars & {
        @include media-breakpoint-up(sm) {
            grid-row: 1;

            &.ads-last {
                @include media-breakpoint-up(sm) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(md) {
                    grid-column-start: 9;
                }

                @include media-breakpoint-up(lg) {
                    grid-column-start: 10;
                }

                @include media-breakpoint-up(xl) {
                    grid-column-start: 11;
                }
            }
        }
    }

    #channels & {
        @include media-breakpoint-up(sm) {
            grid-row: 1 / 3;

            &.ads-last {
                @include media-breakpoint-up(sm) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(md) {
                    grid-column-start: 9;
                }

                @include media-breakpoint-up(lg) {
                    grid-column-start: 10;
                }

                @include media-breakpoint-up(xl) {
                    grid-column-start: 11;
                }
            }
        }
    }

    #videos & {
        @include media-breakpoint-up(sm) {
            grid-row: 1;

            &.ads-last {
                @include media-breakpoint-up(sm) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(md) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(lg) {
                    grid-column-start: 9;
                }

                @include media-breakpoint-up(xl) {
                    grid-column-start: 10;
                }
            }
        }
    }

    #relatedVideos & {
        @include media-breakpoint-up(sm) {
            grid-row: 1;

            &.ads-last {
                @include media-breakpoint-up(sm) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(md) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(lg) {
                    grid-column-start: 9;
                }

                @include media-breakpoint-up(xl) {
                    grid-column-start: 10;
                }
            }
        }
    }

    #associatedVideos & {
        @include media-breakpoint-up(sm) {
            grid-row: 1;

            &.ads-last {
                @include media-breakpoint-up(sm) {
                    grid-column-start: 7;
                }

                @include media-breakpoint-up(md) {
                    grid-column-start: 9;
                }

                @include media-breakpoint-up(lg) {
                    grid-column-start: 10;
                }

                @include media-breakpoint-up(xl) {
                    grid-column-start: 11;
                }
            }
        }
    }
}

@keyframes fadeInOnline {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes flashTextAction {
    from,
    10%,
    to {
        opacity: 1;
    }

    5%,
    25% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }
}
