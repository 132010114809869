/**
 * https://spritegen.website-performance.org/
 */

.flag {
    background-image: url("../img/flags.png");
    background-repeat: no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
}

.flag-cs {
    background-position: -5px -5px;
}

.flag-de {
    background-position: -39px -5px;
}

.flag-en {
    background-position: -73px -5px;
}

.flag-es {
    background-position: -107px -5px;
}

.flag-fr {
    background-position: -141px -5px;
}

.flag-it {
    background-position: -175px -5px;
}

.flag-ja {
    background-position: -209px -5px;
}

.flag-ko {
    background-position: -243px -5px;
}

.flag-nl {
    background-position: -277px -5px;
}

.flag-pl {
    background-position: -311px -5px;
}

.flag-pt {
    background-position: -345px -5px;
}

.flag-rainbow {
    background-position: -379px -5px;
}

.flag-ru {
    background-position: -413px -5px;
}

.flag-zh {
    background-position: -447px -5px;
}
