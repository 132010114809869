#player-container {
    #player-poster,
    #player-poster-vast {
        cursor: pointer;
    }

    .rmp-ad-click-ui-mobile {
        & + .rmp-ad-container-skip {
            bottom: 15px;
        }
    }

    .player-icon {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
        margin-top: -50px;
        background-color: rgba(0, 0, 0, .4);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
            content: '';
            margin-left: 8px;
            border-left: 32px solid #fff;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
        }

        &.icon-mobile {
            width: 50px;
            height: 50px;
            margin-left: -25px;
            margin-top: -25px;

            &:after {
                content: '';
                margin-left: 4px;
                border-left: 16px solid #fff;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
            }
        }
    }
}
